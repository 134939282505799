// src/components/Dashboard.js
import React, { useEffect } from "react";
import { useDispatch , useSelector} from "react-redux";
import Header from "./pages/header";
import { fetchUser } from "../redux/userAction";
import LoadingSinner from "./Spinner/LoadingSinner";

function Dashboard() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
   const isLoading = useSelector((state) => state.user.isLoading);
  const error = useSelector((state) => state.user.error);
  

   useEffect(() => {
     dispatch(fetchUser());
   }, [dispatch]);

   if (isLoading) {
     return (
       <div>
         <LoadingSinner />
       </div>
     );
   }

   if (error) {
     return <div>Error: {error}</div>;
   }


  return (
    <div>
      <Header />
      {user && (
        <div>
          <h1>Hello {user.name}</h1>
          <h2>Welcome to Dashboard</h2>
        </div>
      )}
      
    </div>
  );
}

export default Dashboard;
