import axios from "axios"; // Assuming you're using axios for API calls
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
} from "./actionsType";

import { logout as apiLogout } from "../config/auth";

// Asynchronous action creator for login
export const login = (credentials) => {
  return async (dispatch) => {
    dispatch({ type: LOGIN_REQUEST });

    try {
      // Replace the URL with your API endpoint
      const response = await axios.post(
        "https://pbxapi.isensetradingsolution.net.in/api/login",
        credentials
      );

      dispatch({
        type: LOGIN_SUCCESS,
        payload: response.data, // Assume the API returns user data on success
      });
      // Optionally, save user data to localStorage or perform other side effects here
      localStorage.setItem("token",response.data.token);
    } catch (error) {
      dispatch({
        type: LOGIN_FAILURE,
        payload: error.response ? error.response.data.message : error.message,
      });
    }
  };
};


export const logout = () => {
  return async (dispatch) => {
    try {
      // Use the logout function from auth.js
      await apiLogout();

      // Perform any necessary logout cleanup here
      dispatch({ type: LOGOUT });

      localStorage.removeItem("token");
    } catch (error) {
      console.error("Logout failed:", error.message);
      // Optionally, handle logout failure (e.g., display error notification)
    }
  };
};




// // Synchronous action creator for logout
// export const logout = () => {
//   return (dispatch) => {
//     // Perform any necessary logout cleanup here
//     localStorage.removeItem("token");
//     dispatch({ type: LOGOUT });
//   };
// };
