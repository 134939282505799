import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap"; 
import '../../css/login.css'
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/authAction";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";



function LoginForm() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
   const navigate = useNavigate(); // Hook to navigate programmatically

  const { loading, error,isAuthenticated} = useSelector((state) => state.auth);


  const handleusername = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login({ username, password }));
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard"); // Replace with your dashboard route
    }
  }, [isAuthenticated, navigate]);
 
 

  return (
    <div className="justify-content-md-center login_form">
      <h2 className="text-left mb-4">Sign in</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email </Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter email"
            onChange={handleusername}
            // required
          />
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <div className="d-flex password_sections">
            <Form.Control
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            <div className="hide_show" variant="outline-secondary" onClick={togglePasswordVisibility}>
              {showPassword ? (
                <VisibilityOffIcon/>
              ) : (
                <VisibilityIcon/>
              )}
            </div>
          </div>
        </Form.Group>

        <Button
          variant="primary"
          type="submit"
          className="w-100 mt-3"
          disabled={loading}
        >
          {loading ? <Spinner animation="border" /> : "Continue"}
        </Button>
        {error && <p className="text-danger mt-2">{error}</p>}
      </Form>
    </div>
  );
}

export default LoginForm;
