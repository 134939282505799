import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
} from "./actionsType";
import { getUser as apiGetUser } from "../config/auth"; 


export const fetchUser = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_USER_REQUEST });

    try {
        const userData = await apiGetUser();
        localStorage.setItem("user", JSON.stringify(userData));
      dispatch({
          type: FETCH_USER_SUCCESS,
          payload: userData,
        });

    } catch (error) {
      dispatch({
        type: FETCH_USER_FAILURE,
        payload: error.message,
      });
    }
  };
};
