import React from "react";
import LoginForm from "./components/login/login";
import './css/App.css'

function App() {
  return (
    <div className="App">
      <div className="home_page_title">
        <h4>PBX AGENT</h4>
        <div className="main_page">
          <h1 className="welcome_title">Welcome to PBX AGENTS</h1>
          <h5 className="welcome_subtitle">Please sign in to continue</h5>
        </div>
      </div>
      <LoginForm />
    </div>
  );
}

export default App;
