import React from 'react'
import { CallTimer } from "./CallTimer";
import Mic from "@mui/icons-material/Mic";
import MicOff from "@mui/icons-material/MicOff";
import CallEndIcon from "@mui/icons-material/CallEnd";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PhonePausedIcon from "@mui/icons-material/PhonePaused";
import Stack from "@mui/material/Stack";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { useSessionCall } from 'react-sipjs';
import { SessionState } from "sip.js";

function DailerCallChild(props) {
    const { sessionNowId} = props;
  const {
    isHeld,
    isMuted,
    decline,
    hangup,
    hold,
    mute,
    answer,
    session,
    unhold,
    unmute,
    timer,
    } = useSessionCall(sessionNowId);
    
    console.log("session",sessionNowId,);
  return (
    <div>
      <div className="main_call d-flex">
        
        {session && SessionState.Established === session.state && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span className="dialercall_text1 mt-1">
              <b>
                {" "}
                <SupportAgentIcon style={{ width: 24, height: 24 }} />
              </b>
            </span>
            <span className="dialercall_text">
              {session && session.remoteIdentity._displayName}
            </span>
            {timer?.answeredAt && (
              <CallTimer
                isEnd={session.state === SessionState.Terminated}
                startAt={timer.answeredAt}
              />
            )}
            <Stack style={{ alignItems: "center" }} direction="row" spacing={1}>
              <button
                className={`dialercall_btn ${isMuted ? "muted" : ""}`}
                onClick={isMuted ? unmute : mute}
              >
                {isMuted ? (
                  <MicOff style={{ width: 24, height: 24 }} />
                ) : (
                  <Mic style={{ width: 24, height: 24 }} />
                )}
              </button>
              <button
                className={`dialercall_btn1 ${isHeld ? "inactive" : ""}`}
                onClick={isHeld ? unhold : hold}
              >
                {isHeld ? (
                  <PhonePausedIcon style={{ width: 24, height: 24 }} />
                ) : (
                  <LocalPhoneIcon style={{ width: 24, height: 24 }} />
                )}
              </button>
              <button className="dialercall_btn2" onClick={hangup}>
                <CallEndIcon style={{ width: 24, height: 24 }} />
              </button>
            </Stack>
          </div>
        )}
      </div>
    </div>
  );
}

export default DailerCallChild
