
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import App from "./App";
import Dashboard from "./components/Dashboder";
import PrivateRoute from "./PrivateRoute";
import { SIPProvider } from "react-sipjs";


export default function RouterBranch() {
  return (
    <BrowserRouter>
      <SIPProvider
        options={{
          domain: "pbx.isensetradingsolution.net.in",
          webSocketServer: "wss://pbx.isensetradingsolution.net.in:7443",
        }}
      >
        <Routes>
          <Route path="/login" element={<App />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route path="/" element={<Navigate to="/login" replace />} />
        </Routes>
      </SIPProvider>
    </BrowserRouter>
  );
}


