export const mainUrl = "https://pbxapi.isensetradingsolution.net.in";

export const allowedRoles = ["MEMBER"];

export const configData = {
  app_mode: "production",
  apiUrl: `${mainUrl}/api/`,
  // socketUrl: "https://api.avertisystems.com:5000",
  main_Url: mainUrl,
  REACT_APP_API_URL: "https://api.openweathermap.org/data/2.5",
  REACT_APP_API_KEY: "f56f24967aaf51182d1d4df628297c6d",
};
