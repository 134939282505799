import React, { useEffect, useState } from "react";
import "./dialercall.css";
import { useSIPProvider } from "react-sipjs";
import { useSessionCall } from "react-sipjs";
import { SessionState } from "sip.js";
import CallEndIcon from "@mui/icons-material/CallEnd";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Stack from "@mui/material/Stack";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import RingVolumeIcon from "@mui/icons-material/RingVolume";
import DailerCallChild from "./DailerCallChild";

function Dialercall() {
  const { sessionManager, sessions } = useSIPProvider();
  const sessionIds = Object.keys(sessions);
  const sessionId =
    sessionIds.length > 0
      ? sessionIds[sessionIds.length - 1]
      : "No active sessions";

  const { session,answer, decline } = useSessionCall(sessionId);

  useEffect(() => {
    if (session && session.state === SessionState.Initial) {
      const activeSession = sessionIds.some((id) => {
        const sess = sessions[id];
        return sess.state === SessionState.Established;
      });

      if (activeSession) {
        decline();
      }
    }

    window.addEventListener('beforeprint', decline)

    return () => {
       
      window.addEventListener("beforeprint", decline);
       
     };

  }, [session, sessionIds, sessions, decline]);
  const r = sessionIds.filter((id) => {
    const sess = sessions[id];
    return sess.state === SessionState.Established ? sess.id : null;
  });

  return (
    <>
      <div className="main_call d-flex">
        {session && session.state === SessionState.Initial && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span className={"ringing"} style={{ marginRight: "1rem" }}>
              <RingVolumeIcon color="primary" />
            </span>
            <span className="dialercall_text1 mt-1">
              <b>
                {" "}
                <SupportAgentIcon style={{ width: 24, height: 24 }} />
              </b>{" "}
              {session && session.remoteIdentity._displayName}
            </span>{" "}
            <Stack style={{ alignItems: "center" }} direction="row" spacing={2}>
              <button onClick={answer} className="dialercall_btn3">
                <LocalPhoneIcon style={{ width: 24, height: 24 }} />
              </button>
              <button onClick={decline} className="dialercall_btn2">
                <CallEndIcon style={{ width: 24, height: 24 }} />
              </button>
            </Stack>
            <audio
              src="https://cdn.pixabay.com/audio/2022/03/14/audio_46d2c35d5a.mp3"
              autoPlay
              controls
              style={{ visibility: "hidden", position: "absolute" }}
            />
          </div>
        )}
      </div>
      {r.length > 0 ? (
        <DailerCallChild sessionId={sessionId} sessionNowId={r[0]} />
      ) : null}
    </>
  );
}

export default Dialercall;

