import axios from "axios";
import { configData } from "./api";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  "ngrok-skip-browser-warning": "any",
};

export default async function loginUser(credentials) {
  try {
    const response = await axios({
      method: "post",
      url: `${configData.apiUrl}login`,
      data: {
        username: credentials.email,
        password: credentials.password,
      },
      headers: headers,
    });

    localStorage.setItem("token", response.data.token);
    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    }
  }
}


export async function logout() {
  const token = localStorage.getItem("token");

  headers["Authorization"] = "Bearer " + token;

  const response = await axios.get(`${configData.apiUrl}logout`, {
    headers: headers,
  });

  if (response.status == 200) {
    localStorage.clear();
    return response.data;
  }
}

export async function getUser() {
  const token = localStorage.getItem("token");
  if (token === null) return null;
  headers["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.get(`${configData.apiUrl}user/details`, {
      headers: headers,
    });

    return response.data.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    }
  }
}
