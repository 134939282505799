import React from 'react';
import './spinner.css'

function LoadingSinner() {
  return (
    <div>
      <div className="loader"></div>
    </div>
  );
}

export default LoadingSinner;
